.section  {
  display: flex;
  flex-flow: column wrap;
  gap: var(--spacing-24);
}

.title  {
  font: var(--font-h3-desktop-default);
  font-weight: 800;
}

.links  {
  display: flex;
  flex-flow: column wrap;
  gap: var(--spacing-12);
}

.link  {
  font: var(--font-p2-desktop-default);
  color: var(--white);
  transition: color 0.2s;
}

.link:hover  {
  color: var(--yellow-500);
}

.link:active  {
  color: var(--white);
}

.picto{
  display: flex;
  flex-flow: column wrap;
  font-size: 0;
}

.row {
  flex-flow: row nowrap;
  gap: var(--spacing-24);
}


@media (max-width: 768px) {
  .section{
    gap: var(--spacing-8);
  }

  .title{
    font: var(--font-p1-desktop-default);
    font-weight: 800;
  }

  .link{
    font: var(--font-p2-mobile-default);
  }
}