.light{
  --text-color: var(--blue);
  --link-color: var(--black);
  --bg-color: var(--beige);
}

.dark{
  --text-color: var(--white);
  --link-color: var(--beige);
  --bg-color: var(--black);
}

.wrapper{
  background: var(--bg-color);
}

.container {
  --padding-container: var(--spacing-80);
  display: grid;
  grid-column: full-start / full-end !important;
  grid-template-columns: var(--grid-template-desktop);
}

.contentContainer{
  display: flex;
  flex-flow: column wrap;
  gap: var(--spacing-40);
  padding: var(--padding-container);
  grid-column: main-start / 8;
  color: var(--text-color);
}

.title{
  font: var(--font-h1-desktop-default);
  color: var(--yellow-500);
  font-weight: 800;
}

.links{
  display: flex;
  flex-flow: column nowrap;
  gap: var(--spacing-24);
}

.links .link {
  color: var(--link-color);
  align-self: flex-start;
}

.links .link:hover{
  text-decoration: underline;
}

.links .link:active{
  text-decoration: none;
}

.text, .links .link{
  color: var(--text-color);
  font: var(--font-p1-desktop-default);
  font-weight: 800;
}

.imageContainer{
  max-height: 656px;
  grid-column: 8 / full-end;
}

.imageContainer .image{
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media (max-width: 1024px) {
  .container{
    --padding-container: 0;
    padding: var(--padding-container);
    gap: 0;
    grid-template-columns: var(--grid-template-mobile);
  }
  .contentContainer{
    padding: var(--spacing-40) var(--spacing-24);
  }
  .title{
    font: var(--font-h1-mobile-default);
    font-weight: 800;
  }
  .text, .links .link{
    font: var(--font-p1-mobile-default);
    font-weight: 800;
  }
}
@media (max-width: 608px){
  .container{
    --padding-container: 0;
  }
  .contentContainer{
    grid-column: main-start / main-end;
    padding: var(--spacing-24);
    gap: var(--spacing-24);
  }

  .links{
    gap: var(--spacing-16);
  }

  .imageContainer{
    grid-column: full-start / full-end;
  }
}
