.button {
  position: relative;
  z-index: 1;
  padding: 0 5px 5px 0;
  cursor: pointer;
  opacity: 1;
  transition: .2s ease-in-out opacity;
}

.inside {
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: left;
  line-height: 1.2;
  padding: var(--spacing-12) var(--spacing-16) var(--spacing-12) var(--spacing-24);
  border-width: 1px;
  border-style: solid;
  font-weight: 800;
  transition: .2s ease-in-out;
  transition-property: background-color, color, border-color, opacity, padding, transform;
  white-space: nowrap;
}

.inside::before, .inside::after {
  content: '';
  position: absolute;
  z-index: 1;
  border-style: solid;
  border-width: 0px;
  transition: transform .2s ease-in-out;
}

.inside::before {
  border-bottom-width: 2px;
  border-left-width: 2px;
  border-right-width: 2px;
  left: -1px;
  top: calc(100% + 0.75px);
  height: 4px;
  width: calc(100% - 2.25px);
  transform-origin: top;
  transform: skewX(45deg);
}

.inside::after {
  border-top-width: 2px;
  border-right-width: 2px;
  top: -1px;
  left: calc(100% + 1px);
  height: calc(100% - 0.25px);
  width: 4px;
  transform-origin: left;
  transform: skewY(45deg);
}

.button:not(:disabled):hover .inside {
  transform: translate(5px, 5px);
}

.button:not(:disabled):hover .inside::before {
  transform: skewX(45deg) scaleY(0);
}

.button:not(:disabled):hover .inside::after {
  transform: skewY(45deg) scaleX(0);
}

.button:disabled {
  opacity: 0.5;
  cursor: default;
}

/* LOADER */

.loader {
  position: absolute;
  z-index: 1;
  left: 10px;
  opacity: 0;
  transition: 0.3s linear 0.3s;
  transition-property: opacity;
}

.isLoading .inside {
  padding-left: calc(var(--spacing-24) + var(--spacing-4) + var(--spacing-16));
}

.isLoading .loader {
  animation: 0.3s linear 0.3s fade-in forwards;
}

.icon, .loader {
  --size-icon: 24px;
  height: var(--size-icon);
  width: var(--size-icon);
}

.icon {
  margin-right: var(--spacing-12);
}

.inside.right {
  flex-direction: row-reverse;
}

.inside.right .icon {
  margin-right: 0;
  margin-left: var(--spacing-12);
}

/* COLORS */

.primary {
  background-color: var(--yellow-500);
  color: var(--black);
  border-color: var(--yellow-500);
}

.primary::before, .primary::after {
  border-color: var(--yellow-500);
}

.secondary {
  background-color: var(--beige);
  color: var(--black);
  border-color: var(--blue);
}

.secondary::before, .secondary::after {
  border-color: var(--blue);
}

/* Outline */
.outline .primary, .outline .primary::before, .outline .primary::after {
  background-color: transparent;
  border-color: var(--blue);
  color: var(--black);
}

.outline .secondary, .outline .secondary::before, .outline .secondary::after {
  background-color: transparent;
  border-color: var(--beige);
  color: var(--white);
}

.outline .secondary .icon{
  color: var(--white);
}

/* ANIMATIONS */

@keyframes fade-in {
  from { opacity: 0; }
  to   { opacity: 0.7; }
}