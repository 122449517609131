.footer {
  display: grid;
  grid-template-columns: var(--grid-template-desktop);
  grid-template-rows: auto 1fr;
  background-color: var(--black);
}

.container {
  display: flex;
  flex-flow: column wrap;
  color: var(--white);
  padding-top: var(--spacing-80);
  padding: var(--spacing-80) 0 0 var(--spacing-80);
  gap: var(--spacing-64);
  grid-column: main-start/10;
  grid-row: 1/5;
}

.logoContainer{
  position: relative;
  width: 200px;
  height: 100px;
}
.logoBottomContainer{
  position: relative;
  width: 200px;
  height: 100px;
  align-self: flex-end;
}

.logo, .logoBottom {
  object-fit: contain;
  height: auto;
}


/* Contact */
.contactContainer{
  display: flex;
  flex-flow: column wrap;
}
.contactLink {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  gap: var(--spacing-40);
  color: var(--white);
  transition: color 0.2s;
}

.contactLink:hover {
  color: var(--yellow-500);
}

.contactLink:active {
  color: var(--white);
}

.contactTitle {
  font: var(--font-h2-desktop-default);
  font-weight: 800;
}

.contactPicto {
  --size-picto : 72px;
  width: var(--size-picto);
  height: var(--size-picto);
  flex: 0 0 auto;
}

/* Infos link */
.infos {
  display: flex;
  flex-flow: column wrap;
  gap: var(--spacing-8);
  color: var(--white);
  padding-top: var(--spacing-40);
}

.infos > *  {
  color: var(--white);
  transition: color 0.2s;
}

.infosLink:hover {
  color: var(--yellow-500);
}

.infosLink:active {
  color: var(--white);
}

.phone {
  font-weight: 800;
}


/* Nav Section */
.nav  {
  display: flex;
  flex-flow: row wrap;
  gap: var(--spacing-80);
}

/* QUOTE */
.quoteContainer {
  display: grid;
  grid-area: 1 / 10 / 1 / full-end;
  grid-template-columns: repeat(4,minmax(0,113px)) var(--spacing-80);
  background-color: var(--yellow-500);
}

.quoteContainer > *{
  grid-area: 1 / 1 / 1 / 5;
}

.quoteButton {
  display: flex;
  align-self: flex-end;
}

/* Sub Nav */

.subContainer {
  grid-column: main-start/main-end;
  display: flex;
  flex-flow: column wrap;
  gap: var(--spacing-64);
  padding: var(--spacing-64) var(--spacing-80) var(--spacing-24);
}

.logoContainer {
  grid-column: main-start/main-end;
  grid-row: 3/4;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.subNav {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
}

.copyright {
  font: var(--font-p3-desktop-default);
  color: var(--grey);
}

@media (max-width: 1200px){
  .quoteContainer{
    grid-template-columns: auto;
  }
}


@media (max-width: 1024px) {
  .container{
    padding-inline: var(--spacing-24)
  }
  .contactTitle{
    font: var(--font-h2-mobile-default);
  }
  .contactPicto{
    --size-picto : 48px;
  }
  .quoteContainer{
    margin-left: var(--spacing-16);
  }
}

@media (max-width: 768px) {
  .footer{
    grid-template-columns: var(--grid-template-mobile);
    gap: var(--spacing-40) 0;
  }
  
  .container{
    grid-column: main-start/main-end;
    grid-row: 1/2;
    gap: var(--spacing-40);
  }

  .infos > *{
    font: var(--font-p1-mobile-default);
  }

  .phone{
    font-weight: 800;
  }

  .nav{
    gap: var(--spacing-40);
  }

  .logoContainer{
    width: 180px;
  }

  /* QUOTE */
  .quoteContainer{
    grid-area:2 / main-start / 2 / main-end;
    grid-template-columns: 1fr;
    margin-left: 0;
  }

  .quoteButton{
    display: block;
    width: 100%;
  }

  /* SUB */
  .subContainer{
    grid-column: main-start/main-end;
    grid-row: 3/4;
    padding: var(--spacing-8) var(--spacing-24) var(--spacing-24);
  }

  .subNav{
    flex-flow: column wrap;
    gap: var(--spacing-40);
    align-items: flex-start;
  }
}